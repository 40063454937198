import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Logger, UserService } from '@/services';
import { Heap, MPTracker } from '@/services/analytics';
import { checkIsUserRegisterInThirtyMinutes } from '@/utils';
import { User } from '@/types/models';
import { REFERRAL_CODE_KEY, StatusCode } from '@/constants';
import { useEffectOnce } from '@/hooks';
import { UserContext, UserContextValue } from './context';

type UserProviderState = Pick<UserContextValue, 'user' | 'error' | 'isAuthInitialized' | 'isLoading'>;

const removeDirectusSession = () => {
  // TODO this is temporary code needed to make sure that users are unauthorized after migration by deleting old Directus session
  document.cookie = `directus_refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  localStorage.removeItem('access_token');
  localStorage.removeItem('accessToken');
};

const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [{ user, error, isAuthInitialized, isLoading }, setState] = useState<UserProviderState>({
    user: null,
    error: null,
    isAuthInitialized: false,
    isLoading: false,
  });

  const login = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      isAuthInitialized: true,
    }));

    try {
      const currentUser = await UserService.getCurrentUser();

      setState((prevState) => ({
        ...prevState,
        user: currentUser,
        isLoading: false,
        isAuthInitialized: true,
      }));
    } catch (e: any) {
      setState((prevState) => ({
        ...prevState,
        user: null,
        isLoading: false,
        isAuthInitialized: true,
      }));

      Logger.error(e);
    }
  }, []);

  const logout = useCallback(async () => {
    // TODO fix or leave a commment
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      setState((prevState) => ({
        ...prevState,
        user: null,
        error: null,
        isLoading: false,
      }));
    } catch (e) {
      Logger.error(e);

      setState((prevState) => ({
        ...prevState,
        user: null,
        error: e as Error,
        isLoading: false,
      }));
    } finally {
      MPTracker.reset();
    }
  }, []);

  const updateUser = useCallback((data: Partial<User>) => {
    setState((prevState) => {
      if (!prevState.user) {
        return prevState;
      }
      return {
        ...prevState,
        user: {
          ...prevState.user,
          ...data,
        },
      };
    });
  }, []);

  useEffectOnce(() => {
    removeDirectusSession();

    (async () => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      try {
        const currentUser = await UserService.getCurrentUser();
        Heap.identify(currentUser.email);

        setState({
          user: currentUser,
          error: null,
          isAuthInitialized: true,
          isLoading: false,
        });
      } catch (e: any) {
        if (e.response?.status !== StatusCode.Unauthorized && e.response?.status !== StatusCode.BadRequest) {
          if (JSON.stringify(e).includes('heap')) {
            Logger.error(`Heap error in user provider error=${e}`);
          } else {
            Logger.error(e);
          }
        }

        setState({
          user: null,
          error: e as Error,
          isAuthInitialized: true,
          isLoading: false,
        });
      }
    })();
  });

  useEffect(() => {
    if (!user || user?.inviterCode) {
      return;
    }

    (async () => {
      const shouldUpdateInviteCode = checkIsUserRegisterInThirtyMinutes(user.createdAt);

      if (!shouldUpdateInviteCode) {
        return;
      }

      const referralCode = localStorage.getItem(REFERRAL_CODE_KEY);

      if (!referralCode || referralCode === user.referralCode) {
        return;
      }

      try {
        const updatedUser = await UserService.updateUsersDataById(user.id, { inviter_code: referralCode }, {}, false);
        setState((prevState) => ({ ...prevState, user: updatedUser }));
        localStorage.removeItem(REFERRAL_CODE_KEY);
      } catch (e) {
        Logger.error(e);
      }
    })();
  }, [user]);

  const contextValue = useMemo<UserContextValue>(
    () => ({
      user,
      error,
      isAuthInitialized,
      isLoading,
      login,
      logout,
      updateUser,
    }),
    [user, error, isAuthInitialized, isLoading, logout, login, updateUser],
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export default UserProvider;
