import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const outlineVariant = definePartsStyle(() => {
  return {
    tab: {
      color: 'black.200',
      fontSize: '16px',
      lineHeight: '24px',
      border: '2px solid',
      borderColor: 'transparent',
      borderTopRadius: 'lg',
      borderBottom: 'none',
      padding: '6px 12px',
      gap: '8px',
      _selected: {
        bg: 'white',
        color: 'black.500',
        borderColor: 'inherit',
        borderBottom: 'none',
        mb: '-2px',
      },
      _hover: {
        bg: 'gray.200',
        color: 'black.500',
        borderColor: 'inherit',
        _selected: {
          bg: 'white',
        },
      },
    },
    tablist: {
      borderBottom: '2x solid',
      borderColor: 'inherit',
    },
    tabpanel: {
      borderTop: '2px solid',
      borderColor: 'inherit',
    },
  };
});

const variants = {
  outline: outlineVariant,
};

export const Tabs = defineMultiStyleConfig({ variants });
