import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  fontFamily: 'Kumbh Sans',
  fontWeight: 600,
});

const variantWrapper = defineStyle(() => ({
  color: 'black.200',
  minHeight: 'unset',
  minWidth: 'unset',
  height: 'unset',
  padding: '6px 12px',
  _hover: { color: 'black.500', bg: 'gray.200' },
}));

const variantSolid = defineStyle(() => ({
  color: 'gray.100',
  fontWeight: 600,
  backgroundColor: 'red.500',
  minHeight: 'unset',
  height: 'unset',
  padding: '6px 12px',
  lineHeight: '24px',

  _hover: {
    _disabled: {
      backgroundColor: 'red.500',
    },
  },

  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
    backgroundColor: 'red.500',

    _hover: {
      opacity: 0.4,
      cursor: 'not-allowed',
      boxShadow: 'none',
      backgroundColor: 'red.500',
    },
  },
}));

const variantOutline = defineStyle(() => ({
  color: 'black.500',
  fontWeight: 600,
  backgroundColor: 'gray.200',
  minHeight: 'unset',
  height: 'unset',
  padding: '6px 12px',
  lineHeight: '24px',

  _hover: {
    backgroundColor: 'gray.300',
    _disabled: {
      backgroundColor: 'gray.300',
    },
  },
}));

const sizes = {
  lg: defineStyle({
    height: '4.375rem',
    minWidth: '4.375rem',
    fontSize: 'md',
    fontWeight: 'medium',
    px: 8,
    py: 3,
  }),
  x11: defineStyle({
    height: '3.25rem',
    minWidth: '3.25rem',
    fontSize: 'md',
    fontWeight: 'medium',
    px: 8,
    py: 3,
  }),
  md: defineStyle({
    height: '12',
    minWidth: '12',
    fontSize: 'md',
    fontWeight: 'medium',
    px: 8,
    py: 3,
  }),
  rg: defineStyle({
    height: 10,
    minWidth: 10,
    fontSize: 'md',
    fontWeight: 'medium',
    px: 6,
    py: 2,
  }),
  sm: defineStyle({
    height: '8',
    minWidth: '8',
    fontSize: 'sm',
    fontWeight: 'normal',
    px: 4,
    py: 1.5,
  }),
  xs: defineStyle({
    height: '7',
    minWidth: '7',
    fontSize: 'sm',
    fontWeight: 'normal',
    px: 3,
    py: 1.5,
  }),
};

export const Button = defineStyleConfig({
  baseStyle,
  variants: {
    primaryCustom: variantSolid,
    wrapper: variantWrapper,
    outlineCustom: variantOutline,
  },
});
