import { RequestOptions } from '@/types/api';
import { Api } from '@/services';
import { getNextApiUrl } from '@/utils';
import { NextApiEndpoint, QuestionStatus } from '@/constants';
import { RunQueryResult, SubmitQueryResult } from '@/types/models';
import { Submission } from '@/types/pages/questions';

class SubmitQuery {
  public runQuery = async (
    questionId: number,
    userSolution: string,
    options?: RequestOptions,
  ): Promise<RunQueryResult> => {
    const { data } = await Api.post<RunQueryResult>(getNextApiUrl(NextApiEndpoint.RunSolution, options), {
      questionId,
      userSolution,
    });

    return data;
  };

  public submitQuery = async (
    questionId: number,
    userSolution: string,
    options?: RequestOptions,
  ): Promise<SubmitQueryResult> => {
    const { data } = await Api.post<SubmitQueryResult>(getNextApiUrl(NextApiEndpoint.SubmitSolution, options), {
      questionId,
      userSolution,
    });

    return data;
  };

  public submitPythonQuery = async (questionId: number, userSolution: string, status: QuestionStatus): Promise<any> => {
    const { data } = await Api.post<Submission>(getNextApiUrl(NextApiEndpoint.SubmitPythonSolution), {
      questionId,
      userSolution,
      status,
    });
    return data;
  };
}

export default new SubmitQuery();
