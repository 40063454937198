export const POSTGRES_RUNTIME_CONNECTION_STRING = process.env.POSTGRES_RUNTIME_CONNECTION_STRING as string;
export const MYSQL_RUNTIME_CONNECTION_STRING = process.env.MYSQL_RUNTIME_CONNECTION_STRING as string;

export enum SupportedRuntime {
  Postgres = 'postgres',
  MySQL = 'mysql',
  // Python = 'python',
}

export const MAP_RUNTIMES_TO_TEXT: Record<SupportedRuntime, string> = {
  [SupportedRuntime.Postgres]: 'PostgreSQL 14',
  [SupportedRuntime.MySQL]: 'MySQL',
  // [SupportedRuntimes.Python]: `Python`,
};

// export const MAP_RUNTIME_TO_PROPER_QUERY_FIELD_NAME: Record<
//   SupportedRuntime,
//   keyof Pick<Question, 'properQuery' | 'mysqlProperQuery'>
// > = {
//   [SupportedRuntime.Postgres]: 'properQuery',
//   [SupportedRuntime.MySQL]: 'mysqlProperQuery',
// };

// TODO:
export const MAP_RUNTIME_TO_ACE_EDITOR_MODE: Record<SupportedRuntime, string> = {
  [SupportedRuntime.Postgres]: 'pgsql',
  [SupportedRuntime.MySQL]: 'mysql',
};
